import React, { useEffect, useState } from 'react';
import { 
  CheckCircle, 
  Phone, 
  Palette, 
  Zap, 
  Menu, 
  X, 
  ArrowRight, 
  ArrowLeft,
  Twitter
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

// Mock Website Images (replace with your actual images)
const mockWebsites = [
  // '/images/im1.jpeg',  
  // '/images/im2.jpeg',
  '/images/im3.png',
  '/images/im4.png',
   '/images/im5.png'
];


function WebsiteBuilderPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentMockup, setCurrentMockup] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentMockup((prev) => (prev + 1) % mockWebsites.length);
      }, 3000); // Change image every 3 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);
  const navigate = useNavigate();

  // Animation Variants
  const heroVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const handleContactNavigation = () => {
    navigate('/contact');
  }

  const handleNextMockup = () => {
    setCurrentMockup((prev) => (prev + 1) % mockWebsites.length);
  };

  const handlePrevMockup = () => {
    setCurrentMockup((prev) => (prev - 1 + mockWebsites.length) % mockWebsites.length);
  };

  return (
    <div className="min-h-screen bg-white">
      <section className="relative min-h-screen bg-gradient-to-br from-purple-600 via-indigo-600 to-blue-600 overflow-hidden">
       {/* Navigation */}
     <nav className="absolute top-0 left-0 right-0 p-4 md:p-6 z-50"> 
      <div className="container mx-auto flex justify-between items-center">
     <h1 className="text-xl md:text-2xl font-bold text-white">MinutesToWeb</h1>
    
    {/* Mobile Menu Button */}
    <button 
      className="md:hidden p-2 text-white"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
    </button>

    {/* Desktop Navigation */}
    <div className="hidden md:flex space-x-6 text-white">
      <a href="#features" className="hover:text-gray-200">Features</a>
      <a href="#process" className="hover:text-gray-200">Process</a>
      <a href="#pricing" className="hover:text-gray-200">Pricing</a>
      <Link to="/contact" className="hover:text-gray-200">Contact</Link>
    </div>
  </div>

  {/* Mobile Navigation - Improved Overlay */}
  <AnimatePresence>
  {isMenuOpen && (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="md:hidden fixed inset-0 z-50 bg-purple-900 overflow-hidden"
    >
      {/* Close Button - Positioned at top right */}
      <button 
        onClick={() => setIsMenuOpen(false)}
        className="absolute top-6 right-6 text-white bg-white/20 rounded-full p-2 hover:bg-white/30 transition z-50"
      >
        <X size={24} />
      </button>

      {/* Menu Content */}
      <motion.div 
        initial={{ y: 50, opacity: 0 }}
        animate={{ 
          y: 0, 
          opacity: 1,
          transition: { 
            type: "spring", 
            stiffness: 300, 
            damping: 20 
          } 
        }}
        exit={{ y: 50, opacity: 0 }}
        className="container mx-auto px-6 flex flex-col justify-center h-full relative z-40"
      >
        <div className="space-y-6 text-center">
          {/* Logo or Title */}
          <h2 className="text-3xl font-bold text-white mb-12">MinutesToWeb</h2>

          {/* Menu Items with Staggered Animation */}
          <div className="space-y-8">
            {[
              { href: "#features", label: "Features" },
              { href: "#process", label: "Process" },
              { href: "#pricing", label: "Pricing" },
              { to: "/contact", label: "Contact" }
            ].map((item, index) => (
              <motion.div
                key={item.label}
                initial={{ x: -50, opacity: 0 }}
                animate={{ 
                  x: 0, 
                  opacity: 1,
                  transition: { 
                    delay: 0.1 * index,
                    type: "spring",
                    stiffness: 300
                  } 
                }}
              >
                {item.href ? (
                  <a 
                    href={item.href}
                    onClick={() => setIsMenuOpen(false)}
                    className="block text-3xl text-white py-4 
                    hover:bg-white/10 rounded-lg 
                    transition duration-300 ease-in-out"
                  >
                    {item.label}
                  </a>
                ) : (
                  <Link 
                    to={item.to!}
                    onClick={() => setIsMenuOpen(false)}
                    className="block text-3xl text-white py-4 
                    hover:bg-white/10 rounded-lg 
                    transition duration-300 ease-in-out"
                  >
                    {item.label}
                  </Link>
                )}
              </motion.div>
            ))}
          </div>

          {/* Social Media Links */}
          <div className="mt-12 flex justify-center space-x-6">
            <a 
              href="mailto:contact@minutestoweb.com" 
              className="text-white hover:text-purple-200 transition"
            >
              <Phone size={28} />
            </a>
            <a 
              href="https://twitter.com/minutestoweb" 
              className="text-white hover:text-purple-200 transition"
            >
              <Twitter size={28} />
            </a>
          </div>
        </div>
      </motion.div>

      {/* Background Gradient */}
      <div 
        className="absolute inset-0 bg-gradient-to-br from-purple-600 via-indigo-600 to-blue-600 opacity-90 -z-10"
        style={{
          transform: 'scale(1.1)',
          filter: 'blur(10px)'
        }}
      />
    </motion.div>
  )}
</AnimatePresence>
    </nav>

        {/* Hero Content */}
        <motion.div 
          className="relative container mx-auto px-6 py-24 grid md:grid-cols-2 gap-12 items-center min-h-screen"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {/* Left Column */}
          <motion.div variants={heroVariants} className="z-10">
            <motion.h1 
              className="text-5xl md:text-6xl font-extrabold text-white mb-6 leading-tight"
              variants={heroVariants}
            >
              Crafting Perfect 
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-600">
                Websites
              </span>
            </motion.h1>

            <motion.p 
              className="text-xl text-white/80 mb-8 max-w-xl"
              variants={heroVariants}
            >
              Unlike generic website builders, we create tailored experiences for your unique brand
            </motion.p>

            {/* CTA Buttons */}
            <motion.div 
              className="flex space-x-4"
              variants={heroVariants}
            >
              <button 
                onClick={handleContactNavigation}
                className="flex items-center px-6 py-3 bg-white text-purple-600 
                font-semibold rounded-full hover:bg-purple-100 
                transition-all duration-300 group"
              >
                Get Started
                <ArrowRight 
                  className="ml-2 group-hover:translate-x-1 transition-transform" 
                />
              </button>
            </motion.div>

            {/* Features */}
            <motion.div 
              className="mt-12 grid grid-cols-2 gap-4"
              variants={containerVariants}
            >
              {[
                { icon: CheckCircle, text: "Custom Design" },
                { icon: Zap, text: "Fast Performance" },
                { icon: Palette, text: "SEO Optimized" },
                { icon: Phone, text: "Responsive" }
              ].map((feature, index) => (
                <motion.div 
                  key={index}
                  className="flex items-center space-x-2 text-white"
                  variants={heroVariants}
                >
                  <feature.icon className="w-5 h-5 text-green-400" />
                  <span className="text-sm">{feature.text}</span>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>

          <motion.div 
        className="hidden md:block relative"
        variants={heroVariants}
      >
        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 shadow-2xl">
          {/* Mockup Slider */}
          <div className="relative">
            {/* Mockup Image */}
            <motion.img 
              key={currentMockup}
              src={mockWebsites[currentMockup]} 
              alt={`Website Mockup ${currentMockup + 1}`}
              className="w-full h-auto object-cover rounded-lg"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement;
                console.error('Image load error for:', mockWebsites[currentMockup], e);
                target.onerror = null; // Prevent infinite error loop
                target.src = '/images/im2.jpeg'; // Ensure this fallback image exists
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>
      </motion.div>
      </motion.div>
      </section>


      {/* Features Section */}
<section id="features" className="py-12 md:py-20 bg-gray-50">
  <div className="container mx-auto px-4 md:px-6">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
      {/* Feature 1 */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg animate-slide-up feature 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-purple-50
        group" 
        style={{ animationDelay: '0.1s' }}>
        <CheckCircle className="w-10 h-10 md:w-12 md:h-12 text-purple-600 mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-lg md:text-xl font-semibold mb-2 
          transition-colors duration-300 group-hover:text-purple-600">
          Individual Attention
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          Personalized service with dedicated attention to your specific needs
        </p>
      </div>

      {/* Feature 2 */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg animate-slide-up feature 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-blue-50
        group" 
        style={{ animationDelay: '0.2s' }}>
        <Palette className="w-10 h-10 md:w-12 md:h-12 text-purple-600 mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-lg md:text-xl font-semibold mb-2 
          transition-colors duration-300 group-hover:text-blue-600">
          Creative Design
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          Unique and visually appealing designs tailored to your brand
        </p>
      </div>

      {/* Feature 3 */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg animate-slide-up feature 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-green-50
        group" 
        style={{ animationDelay: '0.3s' }}>
        <Phone className="w-10 h-10 md:w-12 md:h-12 text-purple-600 mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-lg md:text-xl font-semibold mb-2 
          transition-colors duration-300 group-hover:text-green-600">
          24/7 Support
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          Always available to assist you with any inquiries or issues
        </p>
      </div>

      {/* Feature 4 */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg animate-slide-up feature 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-red-50
        group" 
        style={{ animationDelay: '0.4s' }}>
        <Zap className="w-10 h-10 md:w-12 md:h-12 text-purple-600 mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-lg md:text-xl font-semibold mb-2 
          transition-colors duration-300 group-hover:text-red-600">
          Fast Performance
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          Optimized websites for speed and efficiency
        </p>
      </div>
    </div>
  </div>
</section>

      {/* Process Section */}
<section id="process" className="py-12 md:py-20 bg-white">
  <div className="container mx-auto px-4 md:px-6">
    <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-16 animate-fade-in">Our Streamlined Process</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
      <div className="text-center animate-slide-in-left 
        bg-white p-6 rounded-lg shadow-md 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-purple-50 
        group" style={{ animationDelay: '0.1s' }}>
        <Phone className="w-12 h-12 md:w-16 md:h-16 text-purple-600 mx-auto mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-xl md:text-2xl font-semibold mb-4 
          transition-colors duration-300 group-hover:text-purple-600">
          1. Discovery Call
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          In-depth consultation to understand your brand and vision
        </p>
      </div>
      <div className="text-center animate-slide-up 
        bg-white p-6 rounded-lg shadow-md 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-blue-50 
        group" style={{ animationDelay: '0.2s' }}>
        <Palette className="w-12 h-12 md:w-16 md:h-16 text-purple-600 mx-auto mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-xl md:text-2xl font-semibold mb-4 
          transition-colors duration-300 group-hover:text-blue-600">
          2. Custom Design
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          Tailored design based on your unique profile
        </p>
      </div>
      <div className="text-center animate-slide-in-right 
        bg-white p-6 rounded-lg shadow-md 
        transition-all duration-300 ease-in-out 
        hover:shadow-xl hover:scale-105 hover:bg-green-50 
        group" style={{ animationDelay: '0.3s' }}>
        <Zap className="w-12 h-12 md:w-16 md:h-16 text-purple-600 mx-auto mb-4 
          transition-transform duration-300 group-hover:rotate-12 group-hover:scale-110" />
        <h3 className="text-xl md:text-2xl font-semibold mb-4 
          transition-colors duration-300 group-hover:text-green-600">
          3. Rapid Development
        </h3>
        <p className="text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-700">
          Quick turnaround in just 1-2 days
        </p>
      </div>
    </div>
  </div>
</section>

      {/* Pricing Section */}
<section id="pricing" className="py-12 md:py-20 bg-gray-50">
  <div className="container mx-auto px-4 md:px-6">
    <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-16 animate-fade-in">
      Simple, Transparent Pricing
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
      {/* Starter Plan */}
      <div 
        className="bg-white p-6 md:p-8 rounded-lg shadow-lg 
        transition-all duration-300 ease-in-out 
        hover:shadow-2xl hover:scale-105 
        group
        border-2 border-transparent 
        hover:border-purple-300" 
        style={{ animationDelay: '0.2s' }}
      >
        <div className="text-3xl md:text-4xl mb-4 
          transition-transform duration-300 group-hover:rotate-6">
          🚀
        </div>
        <h3 className="text-xl md:text-2xl font-bold mb-4 
          transition-colors duration-300 group-hover:text-purple-600">
          Starter Plan
        </h3>
        <div className="text-2xl md:text-3xl font-bold mb-6 
          transition-colors duration-300 group-hover:text-purple-700">
          $10
          <span className="text-sm text-gray-500 ml-2">/ project</span>
        </div>
        <ul className="space-y-3 mb-8">
          {[
            { text: "3-page website (Existing Templates)", included: true },
            { text: "Mobile responsive design", included: true },
            { text: "1 round of revisions", included: true }
          ].map((feature, index) => (
            <li 
              key={index} 
              className="flex items-center text-sm md:text-base"
            >
              <span className={`mr-2 ${feature.included ? 'text-green-500' : 'text-gray-300'}`}>
                {feature.included ? '✓' : '✗'}
              </span>
              <span 
                className={`
                  transition-colors duration-300 
                  ${feature.included 
                    ? 'group-hover:text-gray-700' 
                    : 'text-gray-400 line-through'
                  }
                `}
              >
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
        <Link 
          to="/contact" 
          className="block text-center 
          bg-purple-600 text-white py-2 md:py-3 px-4 md:px-6 rounded-lg 
          hover:bg-purple-700 transition 
          transform hover:scale-105 
          shadow-md hover:shadow-lg"
        >
          Get Started
        </Link>
      </div>

      {/* Pro Plan */}
      <div 
        className="bg-white p-6 md:p-8 rounded-lg shadow-lg 
        transition-all duration-300 ease-in-out 
        hover:shadow-2xl hover:scale-105 
        group
        border-2 border-purple-600 
        hover:border-purple-700" 
        style={{ animationDelay: '0.4s' }}
      >
        {/* <div className="absolute top-0 right-0 m-4 
          bg-purple-600 text-white px-3 py-1 rounded-full text-xs
          group-hover:bg-purple-700">
          Most Popular
        </div> */}
        <div className="text-3xl md:text-4xl mb-4 
          transition-transform duration-300 group-hover:rotate-6">
          💼
        </div>
        <h3 className="text-xl md:text-2xl font-bold mb-4 
          transition-colors duration-300 group-hover:text-purple-700">
          Pro Plan
        </h3>
        <div className="text-2xl md:text-3xl font-bold mb-6 
          transition-colors duration-300 group-hover:text-purple-800">
          $20
          <span className="text-sm text-gray-500 ml-2">/ project</span>
        </div>
        <ul className="space-y-3 mb-8">
          {[
            { text: "6-page website (Existing Templates)", included: true },
            { text: "Mobile responsive", included: true },
            { text: "Advanced design", included: true },
            { text: "2 rounds of revisions", included: true }
          ].map((feature, index) => (
            <li 
              key={index} 
              className="flex items-center text-sm md:text-base"
            >
              <span className={`mr-2 ${feature.included ? 'text-green-500' : 'text-gray-300'}`}>
                {feature.included ? '✓' : '✗'}
              </span>
              <span 
                className={`
                  transition-colors duration-300 
                  ${feature.included 
                    ? 'group-hover:text-gray-700' 
                    : 'text-gray-400 line-through'
                  }
                `}
              >
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
        <Link 
          to="/contact" 
          className="block text-center 
          bg-purple-600 text-white py-2 md:py-3 px-4 md:px-6 rounded-lg 
          hover:bg-purple-700 transition 
          transform hover:scale-105 
          shadow-md hover:shadow-lg"
        >
          Get Started
        </Link>
      </div>

      {/* Enterprise Plan */}
      <div 
        className="bg-white p-6 md:p-8 rounded-lg shadow-lg 
        transition-all duration-300 ease-in-out 
        hover:shadow-2xl hover:scale-105 
        group
        border-2 border-transparent 
        hover:border-purple-300" 
        style={{ animationDelay: '0.6s' }}
      >
        <div className="text-3xl md:text-4xl mb-4 
          transition-transform duration-300 group-hover:rotate-6">
          🌐
        </div>
        <h3 className="text-xl md:text-2xl font-bold mb-4 
          transition-colors duration-300 group-hover:text-purple-600">
          Enterprise Plan
        </h3>
        <div className="text-2xl md:text-3xl font-bold mb-6 
          transition-colors duration-300 group-hover:text-purple-700">
          Custom
          <span className="text-sm text-gray-500 ml-2">/ tailored solution</span>
        </div>
        <ul className="space-y-3 mb-8">
          {[
            { text: "Multiple pages", included: true },
            { text: "Custom functionality", included: true },
            { text: "Dedicated support", included: true },
            { text: "Ongoing maintenance", included: true }
          ].map((feature, index) => (
            <li 
              key={index} 
              className="flex items-center text-sm md:text-base"
            >
              <span className=" text-green-500 mr-2">✓</span>
              <span className="transition-colors duration-300 group-hover:text-gray-700">
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
        <Link 
          to="/contact" 
          className="block text-center 
          bg-purple-600 text-white py-2 md:py-3 px-4 md:px-6 rounded-lg 
          hover:bg-purple-700 transition 
          transform hover:scale-105 
          shadow-md hover:shadow-lg"
        >
          Get Started
        </Link>
      </div>
    </div>
  </div>
</section>


  {/* Client section */}
<section className="py-12 md:py-20 bg-white">
  <div className="container mx-auto px-4 md:px-6">
    <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-16 animate-fade-in">What Our Clients Say</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
      {/* Testimonial 1 */}
      <div 
        className="bg-white p-6 md:p-8 rounded-lg shadow-lg 
        transition-all duration-300 ease-in-out 
        hover:shadow-2xl hover:scale-105 
        group
        border-2 border-transparent 
        hover:border-purple-300" 
        style={{ animationDelay: '0.2s' }}
      >
        <div className="flex items-center mb-4">
          <div className="text-yellow-400 text-xl">★★★★</div>
        </div>
        <p className="text-gray-600 mb-6 text-sm md:text-base 
          transition-colors duration-300 group-hover:text-gray-800">
          "MinutesToWeb transformed our online presence completely. Their attention to detail and personalized approach made all the difference. Our new website looks stunning and performs exceptionally well."
        </p>
        <div className="mt-4">
          <h4 className="font-semibold 
            transition-colors duration-300 
            group-hover:text-purple-600">
            Sarah Richter
          </h4>
          <p className="text-gray-500 
            transition-colors duration-300 
            group-hover:text-purple-400">
            Tax Consultant
          </p>
        </div>
      </div>

      {/* Testimonial 2 */}
      <div 
        className="bg-white p-6 md:p-8 rounded-lg shadow-lg 
        transition-all duration-300 ease-in-out 
        hover:shadow-2xl hover:scale-105 
        group
        border-2 border-transparent 
        hover:border-blue-300" 
        style={{ animationDelay: '0.4s' }}
      >
        <div className="flex items-center mb-4">
          <div className="text-yellow-400 text-xl">★★★★★</div>
        </div>
        <p className="text-gray-600 mb-6 
          transition-colors duration-300 
          group-hover:text-gray-800">
          "The speed and quality of delivery exceeded our expectations. They understood our brand perfectly and created a website that truly represents who we are. Highly recommended!"
        </p>
        <div className="mt-4">
          <h4 className="font-semibold 
            transition-colors duration-300 
            group-hover:text-blue-600">
            Imran Rashid
          </h4>
          <p className="text-gray-500 
            transition-colors duration-300 
            group-hover:text-blue-400">
            Restaurant Owner
          </p>
        </div>
      </div>

      {/* Testimonial 3 */}
      <div 
        className="bg-white p-6 md:p-8 rounded-lg shadow-lg 
        transition-all duration-300 ease-in-out 
        hover:shadow-2xl hover:scale-105 
        group
        border-2 border-transparent 
        hover:border-green-300" 
        style={{ animationDelay: '0.6s' }}
      >
        <div className="flex items-center mb-4">
          <div className="text-yellow-400 text-xl">★★★★★</div>
        </div>
        <p className="text-gray-600 mb-6 
          transition-colors duration-300 
          group-hover:text-gray-800">
          "Working with MinutesToWeb was a breeze. Their streamlined process and clear communication made the entire project smooth. The final website exceeded all our expectations."
        </p>
        <div className="mt-4">
          <h4 className="font-semibold 
            transition-colors duration-300 
            group-hover:text-green-600">
            Katherine Weiss
          </h4>
          <p className="text-gray-500 
            transition-colors duration-300 
            group-hover:text-green-400">
            Marketing Consultant
          </p>
        </div>
      </div>
    </div>

    {/* Stats Section - Enhanced with Hover Effects */}
    <div className="mt-20 grid md:grid-cols-4 gap-8 text-center">
      {[
        { value: "20+", label: "Websites Delivered", color: "purple" },
        { value: "98%", label: "Client Satisfaction", color: "blue" },
        { value: "12h", label: "Average Response Time", color: "green" },
        { value: "4.5⭐", label: "Average Rating", color: "indigo" }
      ].map((stat, index) => (
        <div 
            key={index}
            className={`p-6 rounded-lg 
              text-gray-800  // Default black text
              transition-all duration-300 ease-in-out 
              hover:shadow-xl hover:scale-105 
              group 
              border-2 border-transparent 
              hover:border-${stat.color}-300
              hover:text-${stat.color}-600  // Change text color on hover
            `} 
            style={{ animationDelay: `${0.2 * (index + 1)}s` }} 
          >
            <div 
              className={`text-4xl font-bold mb-2 
                group-hover:text-${stat.color}-600`} 
            > 
              {stat.value} 
            </div>
            <p 
              className={`text-gray-600 
                group-hover:text-${stat.color}-600`} 
            > 
              {stat.label} 
            </p>
          </div>
      ))}
    </div>
  </div>
</section>

     {/* CTA Section */}
<section 
  className="bg-purple-600 text-white py-20 overflow-hidden relative"
>
  <motion.div 
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ 
      duration: 0.8, 
      ease: "easeOut" 
    }}
    viewport={{ once: true }}
    className="container mx-auto px-6 text-center relative z-10"
  >
    <motion.h2 
      initial={{ scale: 0.8, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{ 
        type: "spring", 
        stiffness: 120, 
        delay: 0.2 
      }}
      className="text-4xl font-bold mb-8"
    >
      Ready to Stand Out?
    </motion.h2>
    
    <motion.p 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ 
        delay: 0.4, 
        duration: 0.6 
      }}
      className="text-xl mb-8 max-w-2xl mx-auto"
    >
      Get your professional website crafted with great detail and precision
    </motion.p>
    
    <motion.button   
      onClick={handleContactNavigation} 
      initial={{ scale: 0.9, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      whileHover={{ 
        scale: 1.05, 
        transition: { duration: 0.2 } 
      }}
      whileTap={{ scale: 0.95 }}
      transition={{ 
        type: "spring", 
        stiffness: 300, 
        delay: 0.6 
      }}
      className="bg-white text-purple-600 px-8 py-3 rounded-full 
      font-semibold hover:bg-gray-100 
      transition duration-300 ease-in-out 
      transform hover:shadow-xl"
    >
      Contact Us Today
    </motion.button>
  </motion.div>

  {/* Background Animated Elements */}
  <motion.div 
    initial={{ opacity: 0, scale: 0.5 }}
    whileInView={{ opacity: 0.1, scale: 1 }}
    transition={{ duration: 1 }}
    className="absolute inset-0 bg-gradient-to-r from-purple-700 to-indigo-600 
    opacity-10 blur-3xl"
  />

  {/* Animated Background Shapes */}
  <motion.div 
    animate={{ 
      rotate: [0, 360], 
      scale: [0.8, 1.2, 0.9],
      x: [-50, 50, -30],
      y: [30, -30, 20]
    }}
    transition={{ 
      repeat: Infinity, 
      duration: 10, 
      ease: "easeInOut" 
    }}
    className="absolute top-10 right-20 w-32 h-32 bg-purple-500/20 rounded-full blur-2xl"
  />

  <motion.div 
    animate={{ 
      rotate: [0, -360], 
      scale: [0.9, 1.1, 0.8],
      x: [30, -50, 40],
      y: [-20, 30, -10]
    }}
    transition={{ 
      repeat: Infinity, 
      duration: 12, 
      ease: "easeInOut" 
    }}
    className="absolute bottom-10 left-20 w-24 h-24 bg-indigo-500/20 rounded-full blur-2xl"
  />
</section>

      <footer>
            <p>© 2024 Minutestoweb. All Rights Reserved.</p>
        </footer>
    </div>
  );
};

export default WebsiteBuilderPage;
