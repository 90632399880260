import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();

  // State variables for form inputs and validation messages
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    industry: 'technology',
    otherIndustry: '',
    budget: '',
    message: '',
    plan:'',
  });

  const [validationMessages, setValidationMessages] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // Validation functions
  const validateName = (name: string) => {
    return name.trim() ? '' : 'Name cannot be empty.';
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      ? ''
      : 'Please enter a valid email address.';
  };

  const validatePhone = (phone: string) => {
    return /^\+?\d{1,4}[-.\s]?\d{10}$/.test(phone) 
      ? '' 
      : 'Please enter a valid phone number with country code.';
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;

    // Update form data
    setFormData({
      ...formData,
      [name]: value,
    });

    // Perform validation on the changed input
    let message = '';
    if (name === 'name') message = validateName(value);
    if (name === 'email') message = validateEmail(value);
    if (name === 'phone') message = validatePhone(value);

    setValidationMessages({
      ...validationMessages,
      [name]: message,
    });
  };

  const handleIndustryChange = (e: { target: { value: string; }; }) => {
    setIsOtherSelected(e.target.value === 'other');
    setFormData({ ...formData, industry: e.target.value });
  };

  const handlePlanChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
   
    // Update form data
    setFormData({
      ...formData,
      [name]: value,
    });

    // Perform validation on the changed input
    let message = '';
    if (name === 'name') message = validateName(value);
    if (name === 'email') message = validateEmail(value);
    if (name === 'phone') message = validatePhone(value);

    setValidationMessages({
      ...validationMessages,
      [name]: message,
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    // Perform final validation check
    const nameError = validateName(formData.name);
    const emailError = validateEmail(formData.email);
    const phoneError = validatePhone(formData.phone);

    setValidationMessages({
      name: nameError,
      email: emailError,
      phone: phoneError,
    });

    if (nameError || emailError || phoneError) {
      return;
    }

    try {
      await fetch('https://formsubmit.co/minutestoweb@gmail.com', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      alert('Message sent successfully!');
      navigate('/');
    } catch (error) {
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-600 to-blue-600">
      <nav className="container mx-auto p-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-white">Minutes To Web</Link>
        </div>
      </nav>
      <div className="container mx-auto px-6 py-12">
        <div className="max-w-lg mx-auto bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
              {validationMessages.name && <p className="text-red-600 text-sm mt-1">{validationMessages.name}</p>}
            </div>

            <div className="mb-6">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
              {validationMessages.email && <p className="text-red-600 text-sm mt-1">{validationMessages.email}</p>}
            </div>

            <div className="mb-6">
              <label htmlFor="phone" className="block text-gray-700 mb-2">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
              {validationMessages.phone && <p className="text-red-600 text-sm mt-1">{validationMessages.phone}</p>}
            </div>

            <div className="mb-6">
              <label htmlFor="industry" className="block text-gray-700 mb-2">Industry</label>
              <select
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleIndustryChange}
                className="w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="technology">Technology</option>
                <option value="healthcare">Healthcare</option>
                <option value="finance">Finance</option>
                <option value="education">Education</option>
                <option value="other">Other</option>
              </select>
              {isOtherSelected && (
                <input
                  type="text"
                  id="otherIndustry"
                  name="otherIndustry"
                  value={formData.otherIndustry}
                  onChange={handleInputChange}
                  className="mt-4 w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="Please specify"
                />
              )}
            </div>

            <div className="mb-6">
              <label htmlFor="plan" className="block text-gray-700 mb-2">Plan</label>
              <select
                id="plan"
                name="plan"
                value={formData.plan}
                onChange={handlePlanChange}
                className="w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="Basic">Basic</option>
                <option value="Pro">Pro</option>
                <option value="Custom">Custom</option>
              </select>
            </div>

            <div className="mb-6">
              <label htmlFor="message" className="block text-gray-700 mb-2">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-purple-600 text-white p-3 rounded font-bold hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
