import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WebsiteBuilderPage from './website-builder-page';
import ContactForm from './contactForm';

const App: React.FC = () => {
  return (
    <div>
    <Routes>
      <Route path="/" element={<WebsiteBuilderPage />} />
      <Route path="/contact" element={<ContactForm />} />
    </Routes>
    </div>
  );
};

export default App;